//
//
//
//
//
//
//

export default {
    name: "textDefault",
    props:{    
        content: Object
    }
}
